import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import FeatureItem from './FeatureItem';
import Container from './Container';

const { TabPane } = Tabs;

const tabsData = [
  {
    icon: 'dmhub',
    dataType: '1',
  },
  {
    icon: 'datahub',
    dataType: '2',
  },
  {
    icon: 'adhub',
    dataType: '3',
  },
  {
    icon: 'aihub',
    dataType: '5',
  },
  {
    icon: 'pechub',
    dataType: '6',
  },
];

const maxKey = tabsData.length;

const TabRender = ({ icon }) => {
  return <div className={`logo ${icon}`} />;
};

TabRender.propTypes = {
  icon: PropTypes.string,
};

let timer = null;
function HomeFeatureTabs({
  title = '真正的一体化营销云产品',
  subTitle = '',
  backgroundRevert,
  paddingTop,
}) {
  const [activeKey, setActiveKey] = useState('1');

  const setTabsAutoPlay = () => {
    timer = setInterval(() => {
      setActiveKey((_current) => {
        const newKey = parseInt(_current, 10) + 1;
        return newKey > maxKey ? '1' : `${newKey}`;
      });
    }, 1000 * 5);
  };

  const clearTabsAutoPlay = () => {
    if (timer) {
      clearInterval(timer);
    }
  };

  // 自动循环
  useEffect(() => {
    setTabsAutoPlay();
    return () => {
      clearTabsAutoPlay();
    };
  }, []);

  // 点击切换
  const onTabsChange = (key) => {
    if (activeKey === key) return;
    setActiveKey(key);
  };

  // 悬停时暂停切换
  const togglePause = (flag) => {
    if (flag) {
      clearTabsAutoPlay();
    } else {
      setTabsAutoPlay();
    }
  };
  return (
    <section
      id="features"
      className={`home-features ${backgroundRevert ? 'revert' : ''}`}
      style={typeof paddingTop === 'number' ? { paddingTop } : null}
    >
      <div className="features-title">
        <Container>
          <p className="title">{title}</p>
          <p className="sub-title">{subTitle}</p>
        </Container>
      </div>
      <div
        className={`content-list-wrapper feature-tabs-wrapper active-${activeKey}`}
        onMouseEnter={() => togglePause(true)}
        onMouseLeave={() => togglePause(false)}
      >
        <Tabs activeKey={activeKey} onChange={onTabsChange} animated>
          {tabsData.map((item) => (
            <TabPane tab={<TabRender {...item} />} key={item.dataType}>
              <FeatureItem type={item.dataType} isVideo />
            </TabPane>
          ))}
        </Tabs>
      </div>
    </section>
  );
}

HomeFeatureTabs.propTypes = {
  backgroundRevert: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  paddingTop: PropTypes.number,
  isVideo: PropTypes.bool,
};

export default HomeFeatureTabs;
