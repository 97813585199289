import React from 'react';
import { Button } from 'antd';
import Container from '../Container';
// import teachmapImg from './images/teach-map.png';
// import logosetImg from './images/logo-set.png';
import './index.scss';

const TeachMapBanner = () => {
  const goToDetail = () => {
    if (window) {
      window.open(`https://b.convertlab.com/p/a1963`, '_blank');
    }
  };
  return (
    <section className="growth-note-banner-section">
      <Container>
        <div className="banner-content">
          <img
            alt="main"
            className="main-img"
            src="//convertlab-website.oss-cn-hangzhou.aliyuncs.com/images/backgroundjpg/growth-note.png"
          />
          <img
            alt="logo"
            className="logo-set"
            src="//convertlab-website.oss-cn-hangzhou.aliyuncs.com/images/backgroundjpg/growth-note-logo.png"
          />
          <h2>Convertlab 联合营销商业研究院</h2>
          <h2>发布《2023年数字化运营 增长指南2.0》</h2>
          <p className="desc">企业数字化案例深度解析</p>
          <div>
            <Button
              type="primary"
              className="detail-btn"
              style={{ backgroundColor: '#BE2026', border: 'none' }}
              onClick={goToDetail}
            >
              免费下载
            </Button>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default TeachMapBanner;
