import React from 'react';
import { Row } from 'antd';
import Container from './Container';
import DownloadItem from './DownloadItem';

function HomeDownloadList() {
  return (
    <section id="download-list" className="download-list">
      <Container fluid="xl">
        <Row className="boxshadow-wrapper">
          <DownloadItem
            title={
              <>
                <div>爱分析×Convertlab</div>
                <div>中国业务型CDP白皮书 </div>
              </>
            }
            id="401"
            pageIndex={0}
            letterSpacing={-1}
            subTitle="爱分析联合Convertlab，解读中国业务型CDP的发展，并从用户价值、业务应用、数据基础三个维度对给出CDP落地建议。"
            href="https://b.convertlab.com/p/7e8e8c"
            // leaveInfo
          />
          <DownloadItem
            title={
              <>
                <div>R3×Convertlab</div>
                <div>营销技术成熟度对应的合作模型与解决方案</div>
              </>
            }
            id="402"
            pageIndex={0}
            letterSpacing={-0.5}
            subTitle="R3管理咨询联合Convertlab，基于各自对市场观察独特的视角共同撰写，旨在为发展中的企业厘清数字化营销思路。"
            href="https://b.convertlab.com/p/da7009"
            // leaveInfo
          />
          <DownloadItem
            title={
              <>
                <div>Convertlab案例集</div>
                <div>详细拆解行业数字化案例</div>
              </>
            }
            id="403"
            pageIndex={0}
            subTitle="Convertlab数字化“业务实施团队”根据多年经验，梳理了众多企业数字化成功案例，深度剖析典型行业、标杆企业的成功经验。"
            href="https://b.convertlab.com/p/dc692"
          />
        </Row>
      </Container>
    </section>
  );
}

export default HomeDownloadList;
