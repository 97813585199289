import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'antd';
import Container from './Container';
import { useSearch } from '../utils/common';

const DATALIST = [
  {
    id: 'recommended-1',
    coverImage: '//convertlab-website.oss-cn-hangzhou.aliyuncs.com/images/backgroundjpg/recommend-cover-1.png',
    coverImageMobile: '//convertlab-website.oss-cn-hangzhou.aliyuncs.com/images/backgroundjpg/recommend-cover-1-mobile.jpg',
    title: '品牌盘活私域\n' + '高效利用CDP助增长',
    description: '这是个典型“私”转“公”广告精准找人的场景，通过充分利用一方数据价值，赋能广告投放，降低加购成本，提升品牌GMV。',
    href: 'https://b.convertlab.com/p/3b4c3',
  },
  {
    id: 'recommended-2',
    coverImage: '//convertlab-website.oss-cn-hangzhou.aliyuncs.com/images/backgroundjpg/recommend-cover-2.png',
    coverImageMobile: '//convertlab-website.oss-cn-hangzhou.aliyuncs.com/images/backgroundjpg/recommend-cover-2-mobile.jpg',
    title: '精细化运营\n' + 'CDP+MA成车企标配',
    description: '各大车企的营销模式，从原来的“线上+线下”的“双引擎”模式，逐渐偏向于线上营销，这种情况的重视度在急剧攀升。',
    href: 'https://b.convertlab.com/p/1e0a36',
  },
  {
    id: 'recommended-3',
    coverImage: '//convertlab-website.oss-cn-hangzhou.aliyuncs.com/images/backgroundjpg/recommend-cover-3.png',
    coverImageMobile: '//convertlab-website.oss-cn-hangzhou.aliyuncs.com/images/backgroundjpg/recommend-cover-3-mobile.jpg',
    titleLetterSpacing: 2, // 标题有2个字符换行到了第3行
    title: 'Ad Hub+MA+PEC 解锁大规模实时营销',
    description: '一方数据被三方数据赋能后，洞察更全画像、精准圈选投放再沉淀，完成隐私增强计算的全域流程闭环广告投放。',
    href: 'https://b.convertlab.com/p/b1f34',
  },
  {
    id: 'recommended-4',
    coverImage: '//convertlab-website.oss-cn-hangzhou.aliyuncs.com/images/backgroundjpg/recommend-cover-4.png',
    coverImageMobile: '//convertlab-website.oss-cn-hangzhou.aliyuncs.com/images/backgroundjpg/recommend-cover-4-mobile.jpg',
    title: 'AI+Martech 赋能全链路数字化营销',
    description: '「AI大模型」的应用价值已不仅仅是生成式AI，它会在内容创意、广告投放、数据策略、私域管理等方面串联起完整的营销流程。',
    href: 'https://b.convertlab.com/p/d7a085',
  },
];

function RecommendedItem({
  id,
  coverImage,
  coverImageMobile,
  subTitle,
  title,
  description,
  href,
  titleLetterSpacing,
  isMobile,
}) {
  const search = useSearch();
  const linkStr = href + search;
  const onItemClick = () => {
    const { innerWidth } = window;
    if (innerWidth > 769) return;
    window.open(linkStr, '_blank');
  };
  return (
    <Col xl={6} lg={12} md={12} sm={12} xs={24} className="recommended-item-col">
      <div className="recommended-item" onClick={onItemClick}>
        <div className="cover-wrapper">
          <img className="recommended-item-cover" src={isMobile ? coverImageMobile : coverImage} alt={id} />
        </div>
        <div className="recommended-item-content">
          <p className={`recommended-item-title ${titleLetterSpacing ? `letter-spacing-${titleLetterSpacing}` : ''}`}>{title}</p>
          <p className="recommended-item-description">{description}</p>
          <a className="more-button" href={linkStr} target="_blank" rel="noopenner noreferrer">立即阅读</a>
        </div>
      </div>
    </Col>
  );
}

RecommendedItem.propTypes = {
  id: PropTypes.string,
  coverImage: PropTypes.string,
  coverImageMobile: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  href: PropTypes.string,
  titleLetterSpacing: PropTypes.number,
  isMobile: PropTypes.bool,
};

function RecommendedReadingList() {
  const [isMobile, setIsMobile] = useState(false);
  const search = useSearch();
  useEffect(() => {
    const { innerWidth } = window;
    if (innerWidth > 769) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, []);
  return (
    <section id="recommended-reading-list" className="recommended-reading-list">
      <Container>
        <div className="recommended-reading-title">
          <p className="title">推荐阅读</p>
          <a className="more-button" href={`/blogs/${search}`} target="_blank" rel="onopenner noreferrer">查看更多 >>></a>
        </div>
        <div className="recommended-list-wrapper">
          <Row gutter={{ xl: 30, lg: 30, md: 30, sm: 15, xs: 15 }}>
            {DATALIST.map((item) => <RecommendedItem key={item.id} {...item} isMobile={isMobile} />)}
          </Row>
        </div>
        <Button className="mobile-more-btn" block onClick={() => window.open(`/blogs/${search}`, '_blank')}>查看更多 >>></Button>
      </Container>
    </section>
  );
}

export default RecommendedReadingList;
