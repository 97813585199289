import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';
import ApplyDialogContext from '../context/applyDialog';
import { beforeDownload, useSearch } from '../utils/common';

function DownloadItem({
  title = '',
  id = '',
  pageIndex = 0,
  subTitle = '',
  href = '',
  hash,
  leaveInfo,
  letterSpacing = 0,
}) {
  const { setHref, setModalType, setPageFrom, setFileId } = useContext(ApplyDialogContext);
  const search = useSearch();

  const linkStr = href + search + (hash ? `#${hash}` : '');
  const onItemClick = () => {
    if (window) {
      window.open(linkStr, '_blank');
    }
  };

  return (
    <Col lg={8} sm={24} className="download-item">
      <p
        className="title"
        onClick={
          leaveInfo
            ? () =>
                beforeDownload(
                  linkStr,
                  leaveInfo,
                  id,
                  setHref,
                  setModalType,
                  'index',
                  setPageFrom,
                  setFileId,
                  pageIndex
                )
            : onItemClick
        }
      >
        {leaveInfo ? `免费下载《${title}》` : title}
      </p>
      <p className="sub-title" style={{ letterSpacing }}>
        {subTitle}
      </p>
      <a id={id} href={linkStr} download={title} rel="nofollow">
        {title}
      </a>
    </Col>
  );
}

DownloadItem.propTypes = {
  title: PropTypes.any,
  id: PropTypes.string,
  pageIndex: PropTypes.number,
  subTitle: PropTypes.string,
  href: PropTypes.string,
  leaveInfo: PropTypes.bool,
  letterSpacing: PropTypes.number,
  hash: PropTypes.string,
};

export default DownloadItem;
