import React from 'react';
import 'antd/dist/antd.css';
import { Carousel } from 'antd';
import HomeBanner from '../components/HomeBanner';
import HomeDownloadList from '../components/HomeDownloadList';
import HomeFeatureTabs from '../components/HomeFeatureTabs';
import HomeLogos from '../components/HomeLogos';
import Layout from '../components/Layout';
import Apply from '../components/Apply';
import HomeSolutionCases from '../components/HomeSolutionCases';
import RecommendedReadingList from '../components/RecommendedReadingList';
// import CMCBanner from '../components/CMCBanner';
// import CPMActivityBanner from '../components/CPMActivityBanner';
// import PolicyBanner from '../components/PolicyBanner';
import AIBanner from '../components/AIBanner';
import GartnerBanner from '../components/GartnerBanner';
import GrowthNoteBanner from '../components/GrowthNoteBanner';
import TeachMapBanner from '../components/TeachMapBanner';

export default () => {
  return (
    <Layout current="home" subCurrent="home">
      <Carousel autoplay className="home-carousel">
        <div>
          <TeachMapBanner />
        </div>
        <div>
          <GrowthNoteBanner />
        </div>
        <div>
          <AIBanner />
        </div>
        <div>
          <GartnerBanner />
        </div>
        <div>
          <HomeBanner />
        </div>
      </Carousel>
      <HomeDownloadList />
      <HomeFeatureTabs
        title="全链路CMC产品"
        subTitle="Convertlab Marketing Cloud | One Cloud All Marketing Needs"
        isVideo
      />
      <RecommendedReadingList />
      <Apply title="即刻申请试用，体验Convertlab数字化营销之旅" display="inline" />
      <HomeSolutionCases />
      <HomeLogos />
    </Layout>
  );
};
