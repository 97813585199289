import React from 'react';
import { Button } from 'antd';
import Container from '../Container';
// import teachmapImg from './images/teach-map.png';
// import logosetImg from './images/logo-set.png';
import './index.scss';

const TeachMapBanner = () => {
  const goToDetail = () => {
    if (window) {
      window.open(`/blog/20230725-1120/`, '_blank');
    }
  };
  return (
    <section className="ai-grow-banner-section">
      <Container>
        <div className="banner-content">
          <img
            alt="main"
            className="main-img"
            src="//convertlab-website.oss-cn-hangzhou.aliyuncs.com/images/backgroundjpg/ai-grow-illustration.png"
          />
          <h3>Convertlab CEO 高鹏：</h3>
          <h2>从生成式AI到合成式AI</h2>
          <p className="desc">MarTech的挑战与大模型的机遇</p>
          <div>
            <Button
              type="primary"
              className="detail-btn"
              style={{ backgroundColor: '#13CDC0', border: 'none' }}
              onClick={goToDetail}
            >
              了解详情
            </Button>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default TeachMapBanner;
