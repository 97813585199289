import React from 'react';
import Container from './Container';
import ApplyButton from './ApplyButton';
import BannerImg from '../images/banner-img.svg';

function HomeBanner() {
  return (
    <section id="banner" className="home-banner-section">
      <Container>
        <div className="banner-content">
          <h1>
            <span className="first">Convertlab</span>
            <span className="second">营销云</span>
            <span className="tag">赋能增长</span>
          </h1>
          <div className="check-wrapper">
            <div className="check-item">
              <span className="iconfont iconcheck-circle font-25" />
              <div>数字化</div>
            </div>
            <div className="check-item">
              <span className="iconfont iconcheck-circle font-25" />
              <div>自动化</div>
            </div>
            <div className="check-item">
              <span className="iconfont iconcheck-circle font-25" />
              <div>智能化</div>
            </div>
          </div>
          <p className="description">
            帮助企业高效运营海量用户，打造品牌特有的个性化用户体验，同时快速收获业务增长。
          </p>
          <ApplyButton size="large" className="green" buttonPosition="middle">
            申请试用
          </ApplyButton>
        </div>
        <img className="banner-image" src={BannerImg} alt="banner" />
      </Container>
    </section>
  );
}

export default HomeBanner;
