import React from 'react';
import { Button } from 'antd';
import Container from '../Container';
import grade from '../../images/gartner/grade.png';
// import leftBottom from '../../images/gartner/leftBottom.png';
// import rightBottom from '../../images/gartner/rightBottom.png';
import './index.scss';

const LenovoBanner = () => {
  const goToDetail = () => {
    if (window) {
      window.open(`/blog/20230111-0712`, '_blank');
    }
  };
  return (
    <section className="gartner-banner-section">
      <div className="background-left-bottom" />
      <div className="background-right-bottom" />
      <Container>
        <div className="banner-content">
          <img alt="main" className="main-img" src={grade} />
          <div className="title">
            Convertlab 入选
            <br style={{ display: 'none' }} /> 2022 Gartner Peer Insights「Voice of the Customer」:
            <div>
              Multichannel Marketing Hubs <br style={{ display: 'none' }} /> (多渠道营销中枢)
            </div>
          </div>
          <div className="subTitle">客户评价「产品能力和交付支持」超全球平均</div>
          <div>
            <Button
              type="primary"
              className="detail-btn"
              style={{ backgroundColor: '#B768F3', border: 'none' }}
              onClick={goToDetail}
            >
              了解详情
            </Button>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default LenovoBanner;
