import React, { useState } from 'react';
import { Tabs, Button, Radio } from 'antd';
import LazyLoad from 'react-lazyload';
import ApplyButton from './ApplyButton';
import Container from './Container';
import Adidas from '../images/adidas.svg';
import Uniqlo from '../images/uniqlo.svg';
import Bvlgari from '../images/bvlgari.svg';
import Ikea from '../images/ikea.svg';
import Cocacola from '../images/cocacola.svg';
import Samsclub from '../images/samsclub.svg';
import CapitaLand from '../images/capita-land.svg';
import Budweiser from '../images/budweiser.svg';
import Dfs from '../images/dfs.svg';
import SpdBank from '../images/spd-bank.svg';
import HuaRun from '../images/huarun.svg';
import Tcl from '../images/tcl.svg';
import StanleyBlackDecker from '../images/stanley-black-decker.svg';
import Asus from '../images/asus.svg';
import Blueair from '../images/blueair.svg';
import ShuiJingFang from '../images/shuijingfang.svg';
import Vorwerk from '../images/vorwerk.svg';
import EfEducation from '../images/ef-education.svg';
import ClubMed from '../images/club-med.svg';
import TheEconomist from '../images/the-economist.svg';
import ChinaBusinessNews from '../images/china-business-news.svg';
import Ddi50 from '../images/ddi50.svg';

// 营销场景方案
const TABS_DATA1 = [
  {
    tabTitle: '数字化营销',
    title: '数字化营销',
    bgId: '12',
    href: '/digitalmarketing/',
    description:
      '数字化营销的核心是能够进行大规模的精准个性化营销，Convertlab一体化营销云从数字化链接、数据管理和洞察到全渠道消费者互动、自动化智能营销以及敏捷营销实践，助力企业建立从方法论到实践落地的“数据驱动增长体系”，真正实现数字化营销增长模式。',
    iconTypeList: [
      { iconId: 'icondigital-1', iconName: '营销的数字化管理' },
      { iconId: 'icondigital-2', iconName: '数据的数字化治理' },
      { iconId: 'icondigital-3', iconName: '数字化营销洞察和分析' },
      { iconId: 'icondigital-4', iconName: '全渠道消费者互动' },
    ],
  },
  {
    tabTitle: 'SCRM',
    title: 'SCRM',
    bgId: '11',
    href: '/scrm/',
    description:
      'Convertlab帮助用户搭建基于CDP客户数据平台+MA营销自动化的全域SCRM解决方案，与传统CRM系统相比，SCRM（Social CRM）是以营销互动为中心的全域客户管理系统，更偏重社交化粉丝的运营和管理，尤其是对微信生态粉丝运营需求的支持。',
    iconTypeList: [
      { iconId: 'iconscrm-1', iconName: '全渠道客户管理' },
      { iconId: 'iconscrm-2', iconName: '社交互动统一管理' },
      { iconId: 'iconscrm-3', iconName: '微信SCRM运营' },
      { iconId: 'iconscrm-4', iconName: '微信营销自动化' },
    ],
  },
  // {
  //   tabTitle: '企业微信营销',
  //   title: '企业微信营销',
  //   bgId: '13',
  //   href: '/',
  //   description: '',
  //   iconTypeList: [
  //     { icon: Clothes, iconName: '营销的数字化管理' },
  //   ],
  // },
  {
    tabTitle: '私域流量运营',
    title: '私域流量运营',
    bgId: '14',
    href: '/privatetraffic/',
    description:
      '私域流量是企业可以有效和主动触达的客户群体，本质是高质量的营销触达和客户关系管理。在获客成本日益高企的流量去中心化时代，越来越多的品牌开始意识到私域流量的DTC运营模式对于获客、留存和转化的重要性。Convertlab企业级私域流量运营解决方案，具备跨平台的流量整合及应用能力，能够统一管理全渠道私域用户的营销互动。',
    iconTypeList: [
      { iconId: 'iconprivate-1', iconName: '企业级私域流量运营' },
      { iconId: 'iconprivate-2', iconName: '私域流量运营的策略核心' },
      { iconId: 'iconprivate-3', iconName: '公域+私域的全链路营销' },
    ],
  },
  {
    tabTitle: '营销活动管理',
    title: '营销活动管理',
    bgId: '15',
    href: '/campaign/',
    description:
      '基于MAP营销自动化平台，Convertlab可以帮助您管理和实现覆盖大规模人群和跨渠道的营销活动，在CDP客户数据平台和AD Hub广告效果管理平台的支持下，营销活动效果将会更精准，提供更好的用户个性化体验。',
    iconTypeList: [
      { iconId: 'iconmanagement-1', iconName: '支持丰富的活动类型' },
      { iconId: 'iconmanagement-2', iconName: '营销活动中台' },
      { iconId: 'iconmanagement-3', iconName: '自动化营销活动' },
      { iconId: 'iconmanagement-4', iconName: '智能化营销活动' },
    ],
  },
  {
    tabTitle: '会员营销',
    title: '会员营销',
    bgId: '10',
    href: '/royalty/',
    description:
      '企业拥有多个渠道的会员数据已经成为常态，为了避免营销活动对会员的重复触达，实现统一的会员营销策略，有必要对分散在全渠道的会员数据进行统一管理。Convertlab连接所有的会员触点和平台，帮助您构建一体化会员系统，实现跨平台的会员私域流量运营模式。',
    iconTypeList: [
      { iconId: 'iconmember-1', iconName: '全渠道会员管理' },
      { iconId: 'iconmember-2', iconName: '构建会员中台体系' },
      { iconId: 'iconmember-3', iconName: '丰富的会员营销场景' },
      { iconId: 'iconmember-4', iconName: '完善的会员营销方案' },
    ],
  },
];

// 行业解决方案
const TABS_DATA2 = [
  {
    tabTitle: '品牌零售',
    title: '品牌零售营销解决方案',
    bgId: '1',
    href: '/brandretail/',
    description:
      'Convertlab在品牌零售行业积累了丰富的营销实践，帮助众多头部客户从全渠道数据洞察和用户标签画像体系建设出发，根据RFM模型和用户旅程设计，构建一整套覆盖线上电商、小程序、微信和线下门店的会员运营体系。帮助品牌零售行业解决营销活动管理、SCRM管理、会员销售占比提升、连带率提升、沉睡会员激活等营销问题，有效提升复购率、客单价和整体营收。',
    iconTypeList: [
      { iconId: 'iconclothes', iconName: '鞋服箱包' },
      { iconId: 'iconfoods', iconName: '美食餐饮' },
      { iconId: 'iconbaby', iconName: '母婴' },
      { iconId: 'iconmakeups', iconName: '美装护肤' },
      { iconId: 'iconjewellery', iconName: '珠宝奢侈品' },
      { iconId: 'iconfurniture', iconName: '家居家具' },
    ],
    logoList: [
      { logo: Adidas, logoName: 'adidas' },
      { logo: Uniqlo, logoName: 'UNIQLO' },
      { logo: Bvlgari, logoName: 'BVLGARI' },
      { logo: Ikea, logoName: 'IKEA' },
      { logo: Cocacola, logoName: 'CocaCola' },
    ],
  },
  {
    tabTitle: '现代零售',
    title: '现代零售营销解决方案',
    bgId: '2',
    href: '/modernretail/',
    description:
      'Convertlab通过搭建全渠道客户/会员运营体系，帮助大型商超、连锁便利店和品类零售等现代零售业态，构建以客户为中心的私域流量运营和智慧零售营销体系，有效提升续卡率、会员消费占比和流量运营效率，实现现代商超零售业的数字化营销转型。',
    iconTypeList: [
      { iconId: 'iconshopping-cart', iconName: '大型商超' },
      { iconId: 'iconshop', iconName: '便利店' },
      { iconId: 'iconbag', iconName: '品类零售' },
    ],
    logoList: [
      { logo: Dfs, logoName: 'DFS' },
      { logo: Samsclub, logoName: 'Samsclub' },
    ],
  },
  {
    tabTitle: '商业综合体',
    title: '商业综合体营销解决方案',
    bgId: '3',
    href: '',
    description:
      '对于现代化的商业综合体和Mall来说，以会员/客户为中心的精准营销为抓手，结合全渠道流量运营的打法，正在成为商业综合体的常见运营套路。Convertlab商业综合体营销解决方案在全国众多商业机构的运营实践中，有效提升会员、流量和营销管理水平，在会员消费占比提升、全渠道会员统一管理、营销活动营收提升、优惠券核销率、拉新引流、老会员促活等核心营销场景上有着丰富的实践经验。',
    logoList: [
      { logo: CapitaLand, logoName: 'CapitaLand' },
      { logo: HuaRun, logoName: 'HuaRun' },
    ],
  },
  {
    tabTitle: '电子商务',
    title: '电子商务行业营销解决方案',
    bgId: '4',
    href: '',
    description:
      '数据化驱动是电商行业的运营发展趋势，Convertlab帮助电商企业建立CDP+MA+AI的智能化营销体系，从客户数据的有效治理和应用、AI智能化商品推荐、全链路广告效果优化、跨平台营销整合等维度推动电商交易量的提升和数据价值变现。',
    iconTypeList: [
      { iconId: 'iconshopping-cart-e', iconName: '综合电商' },
      { iconId: 'iconbag-e', iconName: '品类电商' },
    ],
  },
  {
    tabTitle: '快消行业',
    title: '快消行业营销解决方案',
    bgId: '5',
    href: '/fmcg/',
    description:
      '快消行业面对新零售时代的人-货-场重构，逐渐走出一条搭建数字化驱动的完整营销体系，开启全渠道消费者增长的商业模式。Convertlab以丰富的理论基础和运营实践帮助快消行业的众多头部企业重塑竞争力。',
    iconTypeList: [
      { iconId: 'iconfast-food', iconName: '食品饮料' },
      { iconId: 'iconglass', iconName: '酒水烟草' },
      { iconId: 'iconcapsules', iconName: '非处方药' },
      { iconId: 'iconmakeups', iconName: '日化' },
      { iconId: 'iconprinter', iconName: '办公用品' },
    ],
    logoList: [
      { logo: Budweiser, logoName: 'Budweiser' },
      { logo: ShuiJingFang, logoName: 'ShuiJingFang' },
    ],
  },
  {
    tabTitle: '耐消行业',
    title: '耐消行业营销解决方案',
    bgId: '6',
    href: '/durables/',
    description:
      '耐用消费品是一个客户购买周期较长、客单价较高的行业，随着数字化营销的发展，越来越多的耐消品牌开始发展自己的会员和用户运营体系，用个性化的内容持续地触达和培育客户，从而获取私域流量红利。',
    iconTypeList: [
      { iconId: 'iconsmall-appliances', iconName: '小家电' },
      { iconId: 'iconbig-appliances', iconName: '大家电' },
      { iconId: 'iconphone-1', iconName: '消费电子' },
    ],
    logoList: [
      { logo: Tcl, logoName: 'TCL' },
      { logo: Asus, logoName: 'ASUS' },
      { logo: Vorwerk, logoName: 'Vorwerk' },
      { logo: Blueair, logoName: 'Blueair' },
    ],
  },
  {
    tabTitle: '金融行业',
    title: '金融行业营销解决方案',
    bgId: '7',
    href: '/financial/',
    description:
      '金融业务线上化打破了业务增长与网点覆盖率的关系，随着金融行业市场化的提高和竞争的加剧，金融机构开始重视线上营销渠道、数据的整合与变现、客户运营和用户画像的创建，金融行业迎来了数字化营销的大变革。',
    iconTypeList: [
      { iconId: 'iconbank', iconName: '银行' },
      { iconId: 'iconumbrella', iconName: '保险' },
      { iconId: 'iconfund', iconName: '基金证券' },
      { iconId: 'iconcard', iconName: '消费金融' },
    ],
    logoList: [
      { logo: SpdBank, logoName: 'SpdBank' },
    ],
  },
  {
    tabTitle: 'B2B',
    title: 'B2B行业营销解决方案',
    bgId: '8',
    href: '/b2b/',
    // target: '_blank',
    // rel: 'noreferrer noopener nofollow',
    description:
      '为有效解决B2B行业获客成本高、销售周期长、目标客户难搜寻等营销痛点，Convertlab推出了专门针对B2B行业营销应用的产品——“荟聚”。与快消、品牌零售、汽车、金融等B2C行业不同，B2B的业务模型和营销体系构建更多地围绕客户的全生命周期运营和客户线索的转化，荟聚产品具有全新的功能和UI设计，只为B2B营销。',
    logoList: [
      { logo: StanleyBlackDecker, logoName: 'Stanley Black & Decker', type: 'long' },
      { logo: Ddi50, logoName: 'DDI 50' },
    ],
  },
  {
    tabTitle: '其他',
    title: '其他行业营销解决方案',
    bgId: '9',
    href: '',
    description:
      '作为中国营销云领域的翘楚，Convertlab为不同行业提供具有行业深度的营销场景解决方案和运营手段，服务于汽车&出行行业、房地产行业、文旅产业、教育培训行业、健康产业、会展服务业、本地服务行业等数十个行业大类。懂行业，懂客户，懂营销，Convertlab甚至可以为您定制专属于您的私有化营销解决方案。',
    iconTypeList: [
      { iconId: 'iconbuilding', iconName: '房地产' },
      { iconId: 'iconcar', iconName: '汽车' },
      { iconId: 'iconeducation', iconName: '教育' },
      { iconId: 'iconheart', iconName: '健康' },
      { iconId: 'iconsuitcase', iconName: '文旅' },
      { iconId: 'iconspeech', iconName: '会展' },
      { iconId: 'iconwaiter', iconName: '本地服务' },
    ],
    logoList: [
      { logo: ClubMed, logoName: 'Club Med' },
      { logo: HuaRun, logoName: 'HuaRun' },
      { logo: EfEducation, logoName: 'EfEducation' },
      { logo: ChinaBusinessNews, logoName: 'CBN' },
      { logo: TheEconomist, logoName: 'The Economist' },
    ],
  },
];

const BUTTON_DATA = {
  scene: TABS_DATA1,
  industry: TABS_DATA2,
};

const BUTTON_OPTIONS = [
  { label: '营销场景方案', value: 'scene' },
  { label: '行业解决方案', value: 'industry' },
];

export default function HomeSolutionCases() {
  const [currentBtn, setCurrentBtn] = useState('industry');
  const [activeKey, setActiveKey] = useState('1');

  function onButtonChange(e) {
    setCurrentBtn(e.target.value);
    setActiveKey(BUTTON_DATA[e.target.value][0].bgId);
  }

  function onTabsChange(_activeKey) {
    setActiveKey(_activeKey);
  }

  return (
    <section
      id="solution-cases"
      className={`solution-cases main-background background-${activeKey}`}
    >
      <div className="mask">
        <Container>
          <div className="main-title">解决方案</div>
          <LazyLoad>
            <div>
              <div className="preload bg-1" />
              <div className="preload bg-2" />
              <div className="preload bg-3" />
              <div className="preload bg-4" />
              <div className="preload bg-5" />
              <div className="preload bg-6" />
              <div className="preload bg-7" />
              <div className="preload bg-8" />
              <div className="preload bg-9" />
              <div className="preload bg-10" />
              <div className="preload bg-11" />
              <div className="preload bg-12" />
              {/* <div className="preload bg-13" /> */}
              <div className="preload bg-14" />
              <div className="preload bg-15" />
            </div>
          </LazyLoad>
          <div className="sub-title">
            Convertlab为泛零售、快消、金融、汽车、房地产、B2B等众多行业提供专业的营销解决方案
          </div>
          <div className="top-buttons-wrapper">
            <Radio.Group
              options={BUTTON_OPTIONS}
              onChange={onButtonChange}
              optionType="button"
              value={currentBtn}
            />
          </div>
          <Tabs tabPosition="left" onChange={onTabsChange} activeKey={activeKey}>
            {BUTTON_DATA[currentBtn].map((tabItem) => (
              <Tabs.TabPane tab={tabItem.tabTitle} key={tabItem.bgId}>
                <div className="case-content">
                  <p className="case-content-title">{tabItem.title}</p>
                  <p className="case-content-description">{tabItem.description}</p>
                  <div className="icon-list-wrapper">
                    {(tabItem.iconTypeList || []).map((icon) => (
                      <div className="icon-wrapper" key={icon.iconName}>
                        <span className={`iconfont font-48 ${icon.iconId}`} />
                        {/* <img src={icon.icon} alt={icon.iconName} /> */}
                        <p>{icon.iconName}</p>
                      </div>
                    ))}
                  </div>
                  <div className="content-buttons-wrapper">
                    <ApplyButton phone buttonPosition="middle">
                      申请试用
                    </ApplyButton>
                    {tabItem.href && (
                      <Button ghost>
                        <a href={tabItem.href} target={tabItem.target} rel={tabItem.rel}>了解详情</a>
                      </Button>
                    )}
                  </div>
                  {(tabItem.logoList || []).length > 0 && (
                    <div className="logo-list-wrapper">
                      {tabItem.logoList.map((logo) => (
                        <LazyLoad key={logo.logoName}>
                          <div className={`icon-wrapper ${logo.type}`}>
                            <img src={logo.logo} alt={logo.logoName} />
                          </div>
                        </LazyLoad>
                      ))}
                    </div>
                  )}
                </div>
              </Tabs.TabPane>
            ))}
          </Tabs>
          <LazyLoad>
            <div className="mobile-button-wrapper">
              {BUTTON_DATA[currentBtn].map((tabItem) => (
                <div className={`mobile-button bg-${tabItem.bgId}`} key={tabItem.bgId}>
                  <div className="button-mask">
                    {tabItem.href ? (
                      <a href={tabItem.href} target={tabItem.target} rel={tabItem.rel}>{tabItem.tabTitle}</a>
                    ) : (
                      tabItem.tabTitle
                    )}
                  </div>
                </div>
              ))}
              <div className="mobile-button apply">
                <ApplyButton buttonPosition="middle">申请试用</ApplyButton>
              </div>
            </div>
          </LazyLoad>
        </Container>
      </div>
    </section>
  );
}