import React from 'react';
import { Button } from 'antd';
import Container from '../Container';
import teachmapImg from './images/teach-map.png';
import logosetImg from './images/logo-set.png';
import './index.scss';

const TeachMapBanner = () => {
  const goToDetail = () => {
    if (window) {
      window.open(`/blog/20230505-1618/`, '_blank');
    }
  };
  return (
    <section className="teachmap-banner-section">
      <Container>
        <div className="banner-content">
          <img alt="main" className="main-img" src={teachmapImg} />
          <img alt="logo" className="logo-set" src={logosetImg} />
          <h2>Convertlab入选</h2>
          <h2>2023 全球营销科技图谱</h2>
          <p className="desc">Marketing Technology Landscape Supergraphic</p>
          <div>
            <Button
              type="primary"
              className="detail-btn"
              style={{ backgroundColor: '#BE2026', border: 'none' }}
              onClick={goToDetail}
            >
              了解详情
            </Button>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default TeachMapBanner;
